import React, { useState, useEffect } from 'react';
import "../App";
import UserDashboard from "../UserComponents/UserDashboard";
import { Link, Switch, Route, useHistory } from 'react-router-dom';
import { apiUrl } from '../config/config';
function Login() {
	const [Email, setEmail] = useState("");
	const [Password, setPassword] = useState("");
	const [element, setElement] = useState([]);
	const [Id, setId] = useState("");
	const [user, setUser] = useState("");
	let categoryName = [{}];
	// let Id=[{}];
	const history = useHistory();

	console.warn("History", history);
	async function login() {
		if (Email === "" || Password === "") {
			history.push("/Login")
		}
		console.log(Email, Password);
		let item = { Email, Password };
		let result = await fetch(apiUrl + "login", {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json"
			},
			body: JSON.stringify(item)

		});
		result = await result.json();
		console.log(result)
		//   console.log(Id);
		// if(Email==="" && Password==="")
		// {
		// 	history.push("/")
		// }
		if ((result.Error === null || result.Error === undefined)) {

			localStorage.setItem("User_Info", JSON.stringify(result.userInfo));
			localStorage.setItem('category', JSON.stringify(result.categories));

			history.push("/UserDashboard")
			window.location.reload();
		}

		else {
			history.push("/")
		}

	}

	useEffect(() => {
		//logic for getting a local storage value
		const data = localStorage.getItem('User_Info')
		setUser(JSON.parse(data));

	}, []);


	return (
		<div>
			<link rel="stylesheet" type="text/css" href="../vendor/bootstrap/css/bootstrap.min.css" />
			<div className="limiter">

				<div className="container-login100" style={{ backgroundImage: 'url("images/bg-01.jpg")' }}>
					<div className="wrap-login100">



						<center><img src="./assets/img/logo.png" style={{ width: "150px", height: "100px" }} /></center>


						<span className="login100-form-title p-b-15 p-t-27">
							Log in
						</span>

						<div className="wrap-input100 validate-input" data-validate="Enter username">
							<input className="input100" type="text" onChange={(e) => setEmail(e.target.value)} placeholder="Username" required="required" />
							<span className="focus-input100" data-placeholder="&#xf207;"></span>
						</div>

						<div className="wrap-input100 validate-input" data-validate="Enter password">
							<input className="input100" type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" required="required" />
							<span className="focus-input100" data-placeholder="&#xf191;"></span>
						</div>
						<div className="container-login100-form-btn">
							<button className="login100-form-btn" onClick={login}>Login</button>
						</div>
						<center><div className="mt-4 align-center" style={{ color: 'white' }}>
							<Link to="/SuperAdminLogin" style={{ color: 'white' }} className="mt-4 align-center">Login As Admin</Link>
						</div></center>


					</div>
				</div>
			</div>
			<div id="dropDownSelect1"></div>
		</div>
	)
}
export default Login
