import React, { useState, useEffect } from 'react';
import "../App";
import { Link, Switch, Route, useHistory } from 'react-router-dom';
import Dashboard from "./Dashboard";
function SuperAdminLogin() {
  let user = "work@fuelexperts.com";
  let pass = "fuelexperts@123";
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  let item = { Email, Password };
  console.log(item)
  const history = useHistory();


  function login() {
    if (Email === "" && Password === "") {
      history.push("/")
    }
    else if (user === Email && pass === Password) {
      localStorage.setItem('admin', JSON.stringify(item))
      history.push("/Dashboard");
      window.location.reload(true);
    }

    else {
      history.push("/")
    }
  }



  return (
    <div>
      <link rel="stylesheet" type="text/css" href="../vendor/bootstrap/css/bootstrap.min.css" />
      <div className="limiter">

        <div className="container-login100" style={{ backgroundImage: 'url("images/bg-01.jpg")' }}>
          <div className="wrap-login100">



            <center><img src="./assets/img/logo.png" style={{ width: "150px", height: "100px" }} /></center>


            <span className="login100-form-title p-b-15 p-t-27">
              Login As Admin
            </span>

            <div className="wrap-input100 validate-input" data-validate="Enter username">
              <input className="input100" type="text" onChange={(e) => setEmail(e.target.value)} placeholder="Username" required="required" />
              <span className="focus-input100" data-placeholder="&#xf207;"></span>
            </div>

            <div className="wrap-input100 validate-input" data-validate="Enter password">
              <input className="input100" type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" required="required" />
              <span className="focus-input100" data-placeholder="&#xf191;"></span>
            </div>
            <div className="container-login100-form-btn">
              <button className="login100-form-btn" onClick={login}>Login</button>
            </div>



          </div>
        </div>
      </div>
      <div id="dropDownSelect1"></div>
    </div>
  )
}
export default SuperAdminLogin
