import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import SideNavigation from './SideNavigation';
import { apiUrl } from '../config/config';
import UpdateCategory from './UpdateCategory';
import { getDefaultNormalizer } from '@testing-library/dom';

function Logging() {
  const [Category, setCategory] = useState([]);
  const [Status, setStatus] = useState("");
  const [date, setdate] = useState("");
  const [Name, setName] = useState("");
  const history = useHistory();
  const [user, setUser] = useState([]);
  const [NotificationCount, setNotificationCount] = useState("");
  const [Notification, setNotification] = useState([]);
  useEffect(() => {
    fetch(apiUrl + "countnotification").then((result) => {
      result.json().then((resp) => {
        console.log('notify', resp)
        setNotificationCount(resp);
      })
    })
  }, [])
  useEffect(() => {
    fetch(apiUrl + "notification").then((result) => {
      result.json().then((resp) => {
        console.log('notify', resp)
        setNotification(resp);
      })
    })
  }, [])
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('User_Info')
    setUser(JSON.parse(data));

  }, []);
  let UserName = "Hi Admin";
  function logout() {
    localStorage.clear();
    history.push('/Login');
    window.location.reload(true);
  }

  useEffect(() => {
    fetch(apiUrl + "getlogs").then((result) => {
      result.json().then((resp) => {
        setCategory(resp);
      })
    })
  }, [])


  function search() {

    fetch(apiUrl + "searchlogs/" + date).then((data) => {
      data.json().then((resp) => {
        // setName({searchData:resp});
        setCategory(resp);

      })
    })



  }
  function searches() {

    fetch(apiUrl + "searchtologs/" + Name).then((data) => {
      data.json().then((resp) => {
        setCategory(resp);
      })
    })



  }

  // function UpdateStatus(rowItem) {  
  //     rowItem.Status = rowItem.Status ? false : true;
  //     console.log(rowItem.Status);
  //     const users = Category.map((item)=>{
  //         if(item.Id === rowItem.Id){
  //             item.Status = rowItem.Status;
  //         }
  //         return item;
  //     });
  //     setCategory(users);
  //     let Id=rowItem.Id
  //     let Status=rowItem.Status;
  //     let data={Id,Status}; 
  //     fetch(apiUrl+"statuscategory",{
  //         method:'Put',
  //         headers:{
  //             "Content-Type":"application/json",
  //             "Accept":"application/json"
  //         },
  //         body:JSON.stringify(data)
  //     }).then((result)=>{
  //         console.warn("result",result);


  //     })  

  // }

  // function addcat()
  // {
  //     history.push('/AddCategory');
  //     window.location.reload()
  // }
  // function upCategory(id)
  // {
  //     localStorage.setItem('updateCategoryId',JSON.stringify(id))
  //     history.push('/UpdateCategory')
  //     window.location.reload(true);
  // }


  // function Delete(Id)
  // {
  //     let data={Id};
  //     fetch(apiUrl+"deletecategory",{
  //         method:'Delete',
  //         headers:{
  //             "Content-Type":"application/json",
  //             "Accept":"application/json"
  //         },
  //         body:JSON.stringify(data)
  //     }).then((result)=>{
  //         console.warn("result",result);
  //         window.location.reload()

  //     })

  // }
  return (
    <>

      <SideNavigation />

      <div className="main-panel">
        {/* <!-- Navbar --> */}
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
          <div class="container-fluid">
            <div class="navbar-wrapper">
              <h4 class="text-primary" style={{ fontSize: '15px' }}><b>Logging</b></h4>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">


              <ul class="navbar-nav">
                <li class="nav-item dropdown">
                  <a class="nav-link" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">notifications</i>
                    <span class="notification">{NotificationCount}</span>
                    <p class="d-lg-none d-md-block">
                      Some Actions
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    {Notification.map((item) =>
                      <>
                        <button class="dropdown-item">
                          <b> {item.Message}</b>
                        </button>
                      </>
                    )
                    }
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h4 className="mr-4 text-primary" style={{ fontSize: '15px' }}><i class="fa fa-sign-out"></i>Hi Admin</h4>
                    <p class="d-lg-none d-md-block">
                      <Link onClick={logout} >Log out</Link>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">

                    {/* <div class="dropdown-divider"></div> */}
                    <Link class="dropdown-item" onClick={logout}>Log out</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* <!-- End Navbar --> */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">

              <div className="col-md-6 ">

                <div className="row">
                  <div className="col-md-12 float-left">
                    <form className="form-inline">
                      {/* <label>Search By Date</label><br/> */}
                      <input type="date" className="form-control w-100"
                        onChange={(e) => setdate(e.target.value)}
                        style={{ width: '50%' }} />
                      <button className="btn btn-danger form-control" onClick={search}>Go</button>

                    </form>
                  </div>
                </div>



              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12 float-left">
                    <form className="form-inline w-100">
                      {/* <label>From </label> */}
                      <input type="text" placeholder="Search By Name" className="form-control w-100"
                        onChange={(e) => setName(e.target.value)}
                        style={{ width: '50%' }} />

                      <button className="btn btn-danger form-control" onClick={searches}>Go</button>
                    </form>

                  </div>
                </div>



              </div>



              <div class="table-responsive mt-5">
                <table className="table">
                  <thead className=" text-primary">
                    <th>Id</th>
                    <th>Date</th>
                    <th>Activity</th>

                  </thead>
                  <tbody>

                    {
                      Category.map((item, index) =>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.Date}</td>
                          <td>{item.Message}</td>


                        </tr>

                      )

                    }

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );

}
export default Logging