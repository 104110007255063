import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import SideNavigation from './SideNavigation';
import { apiUrl } from '../config/config';
import UpdateCategory from './UpdateCategory';
import { getDefaultNormalizer } from '@testing-library/dom';

function Category() {
  const [Category, setCategory] = useState([]);
  const [Status, setStatus] = useState("");
  const history = useHistory();
  const [user, setUser] = useState([]);
  const [NotificationCount, setNotificationCount] = useState("");
  const [Notification, setNotification] = useState([]);
  useEffect(() => {
    fetch(apiUrl + "countnotification").then((result) => {
      result.json().then((resp) => {
        console.log('notify', resp)
        setNotificationCount(resp);
      })
    })
  }, [])
  useEffect(() => {
    fetch(apiUrl + "notification").then((result) => {
      result.json().then((resp) => {
        console.log('notify', resp)
        setNotification(resp);
      })
    })
  }, [])
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('User_Info')
    setUser(JSON.parse(data));

  }, []);
  let UserName = "Hi Admin";
  function logout() {
    localStorage.clear();
    history.push('/Login');
    window.location.reload(true);
  }
  function EditProfile() {
    history.push('/EditProfile');
    window.location.reload(true);
  }
  useEffect(() => {
    fetch(apiUrl + "categoryfetch").then((result) => {
      result.json().then((resp) => {
        setCategory(resp);
      })
    })
  }, [])

  console.log('categ catg', Category);

  function search(key) {

    fetch(apiUrl + "searchcategory/" + key).then((data) => {
      data.json().then((resp) => {
        setCategory(resp);
      })
    })



  }

  function UpdateStatus(rowItem) {
    rowItem.Status = rowItem.Status ? false : true;
    console.log(rowItem.Status);
    const users = Category.map((item) => {
      if (item.Id === rowItem.Id) {
        item.Status = rowItem.Status;
      }
      return item;
    });
    setCategory(users);
    let Id = rowItem.Id
    let Status = rowItem.Status;
    let data = { Id, Status };
    fetch(apiUrl + "statuscategory", {
      method: 'Put',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(data)
    }).then((result) => {
      console.warn("result", result);


    })

  }

  function addcat() {
    history.push('/AddCategory');
    window.location.reload()
  }
  function upCategory(id) {
    localStorage.setItem('updateCategoryId', JSON.stringify(id))
    history.push('/UpdateCategory')
    window.location.reload(true);
  }


  function Delete(Id) {
    let data = { Id };
    fetch(apiUrl + "deletecategory", {
      method: 'Delete',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(data)
    }).then((result) => {
      console.warn("result", result);
      window.location.reload()

    })

  }
  return (
    <>

      <SideNavigation />

      <div className="main-panel">
        {/* <!-- Navbar --> */}
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
          <div class="container-fluid">
            <div class="navbar-wrapper">
              <h4 class="text-primary" style={{ fontSize: '15px' }}><b>Category</b></h4>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">


              <ul class="navbar-nav">
                <li class="nav-item dropdown">
                  <a class="nav-link" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">notifications</i>
                    <span class="notification">{NotificationCount}</span>
                    <p class="d-lg-none d-md-block">
                      Some Actions
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    {Notification.map((item) =>
                      <>
                        <button class="dropdown-item">
                          <b> {item.Message}</b>
                        </button>
                      </>
                    )
                    }
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h4 className="mr-4 text-primary" style={{ fontSize: '15px' }}><i class="fa fa-sign-out"></i>Hi Admin</h4>
                    <p class="d-lg-none d-md-block">
                      <Link onClick={logout} >Log out</Link>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">

                    {/* <div class="dropdown-divider"></div> */}
                    <Link class="dropdown-item" onClick={logout}>Log out</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* <!-- End Navbar --> */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <button type="submit" onClick={addcat} className="btn btn-primary mt-4 ml-5">
                  Add Category
                </button>

              </div>

              <div className="col-md-6 ">
                <label>Search By Name</label>
                <input type="text" className="form-control"
                  onChange={(e) => search(e.target.value)}
                  style={{ width: '72%' }} />
              </div>


              <div class="table-responsive mt-5">
                <table className="table">
                  <thead className=" text-primary">
                    <th>Id</th>
                    <th>Category</th>

                    <th>Status</th>
                    <th>Update</th>
                    <th>Delete</th>
                  </thead>
                  <tbody>

                    {
                      Category.map((item, index) =>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.CategoryName}</td>


                          <td>

                            <button
                              id={"item_" + item.Id}
                              onClick={() => UpdateStatus(item)}
                              style={{ width: '100px' }}
                              className="">
                              {!item.Status ?
                                <button className='btn btn-primary w-100'>
                                  Active
                                </button>
                                :
                                <button className='btn btn-danger w-100'>
                                  InActive</button>}
                            </button>
                          </td>
                          <td>
                            <button className="btn btn-primary"
                              onClick={() => upCategory(item.Id)}
                            >

                              Update

                            </button>

                          </td>
                          <td>

                            <button className="btn btn-danger"
                              onClick={() => Delete(item.Id)}
                            >

                              Delete

                            </button>

                          </td>
                        </tr>

                      )

                    }

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );

}
export default Category