import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { MultiSelect } from 'react-multi-select-component';
import SideNavigation from './SideNavigation';
import { apiUrl } from '../config/config';

function RegisterUser() {
  const [Name, setName] = useState("");
  const [Contact, setContact] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Designation, setDesignation] = useState("");
  const history = useHistory();
  const [Category, setCategory] = useState([]);
  const [ViewAccess, setViewAccess] = useState("Not");
  const [EditAccess, setEditAccess] = useState("Not");
  const [id, setid] = useState();
  const [category, setcategory] = useState([]);
  const [usercategory, setusercategory] = useState([]);
  const [msg, setMSG] = useState("");
  let Category_id = [{}];
  let Category_Id = [{}];
  const [user, setUser] = useState([]);
  const [NotificationCount, setNotificationCount] = useState("");
  const [Notification, setNotification] = useState([]);
  useEffect(() => {
    fetch(apiUrl + "countnotification").then((result) => {
      result.json().then((resp) => {
        console.log('notify', resp)
        setNotificationCount(resp);
      })
    })
  }, [])
  useEffect(() => {
    fetch(apiUrl + "notification").then((result) => {
      result.json().then((resp) => {
        console.log('notify', resp)
        setNotification(resp);
      })
    })
  }, [])
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('User_Info')
    setUser(JSON.parse(data));

  }, []);
  let UserName = "Hi Admin";
  function logout() {
    localStorage.clear();
    history.push('/Login');
    window.location.reload(true);
  }

  const [UserCategory, setUserCategory] = useState([]);
  for (let index = 0; index < UserCategory.length; index++) {
    let element = UserCategory[index];
    Category_Id[index] = element['Id'];

  }
  console.log('ok', Category_Id);

  function saveUser(event) {
    event.preventDefault();

    if (ViewAccess === "Yes" || EditAccess === "Yes") {
      // console.warn(Name,Contact,Email,Password,Designation,Category_Id,);
      let data = { Name, Contact, Email, Password, Designation, Category_Id, Category_id, ViewAccess, EditAccess };
      fetch(apiUrl + "registeruser", {
        method: 'Post',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify(data)
      }).then((result) => {
        console.warn("result", result);
      })
    }
    else {
      console.warn(Name, Contact, Email, Password, Designation, Category_Id, Category_id);
      let data = { Name, Contact, Email, Password, Designation, Category_Id, Category_id, ViewAccess, EditAccess };
      fetch(apiUrl + "registeruser", {
        method: 'Post',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify(data)
      }).then((result) => {
        console.warn("result", result);
      })
    }
    if (Name === "" || Designation === "" || Password === "" || Email === "" || Contact === "") {
      setMSG(null)
    }
    else {
      setMSG("User Register Successfully")
    }

  }

  useEffect(() => {
    fetch(apiUrl + "categoryfetchindividual").then((result) => {
      result.json().then((resp) => {
        setCategory(resp);
        console.log("result", resp);
      })
    })
  }, [])
  function goback(e) {
    e.preventDefault()
    history.push('/AllUsers');
    window.location.reload()
  }
  for (let index = 0; index < usercategory.length; index++) {
    let element = usercategory[index];
    Category_id[index] = element['Id'];
  }

  Category.map((element, index) =>
    <>
      {element['value'] = element['Id']}
       {element['label'] = element['CategoryName']}
    </>
  );

  async function check(event) {
    event.preventDefault();
    // window.location.reload(false);
    let data = { Category_Id };
    let result = await fetch(apiUrl + "subcategoryfetchindividual", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(data)

    });
    result = await result.json();
    setcategory(result)
  }


  category.map((element, index) =>
    <>
      {element['value'] = element['Id']}
       { element['label'] = element['SubCategoryName']}
    </>
  )
  return (
    <>

      <SideNavigation />

      <div className="main-panel " >
        {/* <!-- Navbar --> */}
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
          <div class="container-fluid">
            <div class="navbar-wrapper">
              <h4 class="text-primary" style={{ fontSize: '15px' }}><b>Register User</b></h4>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">


              <ul class="navbar-nav">
                <li class="nav-item dropdown">
                  <a class="nav-link" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">notifications</i>
                    <span class="notification">{NotificationCount}</span>
                    <p class="d-lg-none d-md-block">
                      Some Actions
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    {Notification.map((item) =>
                      <>
                        <button class="dropdown-item">
                          <b> {item.Message}</b>
                        </button>
                      </>
                    )
                    }
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h4 className="mr-4 text-primary" style={{ fontSize: '15px' }}><i class="fa fa-sign-out"></i>Hi Admin</h4>
                    <p class="d-lg-none d-md-block">
                      <Link onClick={logout} >Log out</Link>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">

                    {/* <div class="dropdown-divider"></div> */}
                    <Link class="dropdown-item" onClick={logout}>Log out</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>


        {/* <!-- End Navbar --> */}
        <div className="content">
          <div className="container-fluid">
            <center> {(msg === null || msg === undefined) ?
              <div className="alert alert-danger mr-4" style={{ width: '48%' }}>All Fields Are Required</div> :
              (msg === "") ? ""
                : <div className="alert alert-success mr-4" style={{ width: '48%' }}>{msg}</div>
            } </center>
            <form>
              <div className="row">



                <div className="mt-3 col-md-4 float-left">
                  <label className="" style={{ width: '50px', color: 'grey' }}>Name<span className="text-danger ml-2">*</span></label>
                  <input type="text" onChange={(e) => setName(e.target.value)} value={Name} className="  form-control w-75" required="required" />
                </div>
                <div className="mt-3 col-md-4 float-left">
                  <label className="" style={{ width: '65px', color: 'grey' }}>Contact<span className="text-danger ml-2">*</span></label>
                  <input type="text" onChange={(e) => setContact(e.target.value)} value={Contact} className="w-75   form-control" required="required" />
                </div>
                <div className="mt-3 col-md-4 float-left">
                  <label className="" style={{ width: '50px', color: 'grey' }}>Email<span className="text-danger ml-2">*</span></label>
                  <input type="email" onChange={(e) => setEmail(e.target.value)} value={Email} className="w-75   form-control" required="required" />
                </div>
              </div>
              <div className="row my-2">
                <div className="col-md-2"></div>
                <div className="col-md-4 float-left">
                  <label className="" style={{ width: '80px', color: 'grey' }}>Password<span className="text-danger ml-2">*</span></label>
                  <input type="password" onChange={(e) => setPassword(e.target.value)} value={Password} className="w-75 form-control" required="required" />
                </div>
                <div className="col-md-4 float-left">
                  <label className="" style={{ width: '90px', color: 'grey' }}>Designation<span className="text-danger ml-2">*</span></label>
                  <input type="text" onChange={(e) => setDesignation(e.target.value)} value={Designation} className="w-75  form-control" required="required" />
                </div>
              </div>
              <div className="row my-4">

              </div>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <center>

                    <h6>Main Category<span className="text-danger ml-2">*</span></h6>
                    <MultiSelect

                      options={Category}
                      value={UserCategory}
                      onChange={setUserCategory}


                    />



                    {

                    }

                  </center>
                </div>

              </div>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <center>

                    <h6 className="my-3">Sub Category<span className="text-danger ml-2">*</span></h6>
                    <button onClick={check} className="btn btn-primary mb-3">Click Me And Select Sub Category</button>
                    <MultiSelect

                      options={category}
                      value={usercategory}
                      onChange={setusercategory}

                    />



                    {

                    }

                  </center>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-4">

                </div>
                <div className="col-md-2 float-left">
                  <center> <label>Allow View <span style={{ color: 'green' }}>*</span></label>
                    <input type="checkbox" className="ml-3" onClick={() => setViewAccess("Yes")} /></center>
                </div>
                <div className="col-md-2 float-left">
                  <center><label>Allow Edit <span style={{ color: 'green' }}>*</span></label>
                    <input type="checkbox" className="ml-3" onClick={() => setEditAccess("Yes")} /></center>
                </div>

              </div>
              <div className="row ">
                <div className="col-md-4"></div>
                <div className="col-md-2 float-left">
                  <button type="submit" onClick={goback}
                    className="btn btn-danger
             mt-4 "
                    style={{ width: '140px', height: '40px' }}>

                    Go Back
                  </button>
                </div>
                <div className="col-md-2 float-left">
                  <button type="submit"
                    onClick={saveUser}
                    className="btn btn-primary  mt-4"
                    style={{ width: '140px', height: '40px' }}>
                    Register User
                  </button>

                </div>

              </div>
            </form>

          </div>
          <div className="col-md-1"></div>
        </div>


      </div>

    </>
  );

}
export default RegisterUser