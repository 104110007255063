import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import SideNavigation from './SideNavigation';
import { MultiSelect } from 'react-multi-select-component';

import { apiUrl } from '../config/config';

function SubCategory() {
  const [Name, setName] = useState("");
  const [Description, setDescription] = useState("");
  const [Category, setCategory] = useState([]);
  const [msg, setMSG] = useState("");
  const [state, setData] = useState({
    users: ''
  });
  const history = useHistory();
  const [user, setUser] = useState([]);
  let Category_Id = [{}];
  const [NotificationCount, setNotificationCount] = useState("");
  const [Notification, setNotification] = useState([]);
  useEffect(() => {
    fetch(apiUrl + "countnotification").then((result) => {
      result.json().then((resp) => {
        console.log('notify', resp)
        setNotificationCount(resp);
      })
    })
  }, [])
  useEffect(() => {
    fetch(apiUrl + "notification").then((result) => {
      result.json().then((resp) => {
        console.log('notify', resp)
        setNotification(resp);
      })
    })
  }, [])
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('User_Info')
    setUser(JSON.parse(data));

  }, []);
  let UserName = "Hi Admin";
  function logout() {
    localStorage.clear();
    history.push('/Login');
    window.location.reload(true);
  }
  const [UserCategory, setUserCategory] = useState([]);
  for (let index = 0; index < UserCategory.length; index++) {
    let element = UserCategory[index];
    Category_Id[index] = element['Id'];
  }
  useEffect(() => {
    fetch(apiUrl + "categoryfetchindividual").then((result) => {
      result.json().then((resp) => {
        setCategory(resp);
        console.log("result", resp);
      })
    })
  }, [])
  Category.map((element, index) =>
    <>
      {element['value'] = element['Id'],
        element['label'] = element['CategoryName']}
    </>
  );

  function saveUser() {
    let element;
    for (let index = 0; index < Category_Id.length; index++) {
      element = Category_Id[index];
    }
    let data = { Name, Description, element };

    fetch(apiUrl + "addsubcategory", {
      method: 'Post',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(data)
    }).then((result) => {
      console.warn("result", result);
    })
    if (Name === "" || Description === "") {
      setMSG(null)
    }
    else {
      setMSG("Sub Category Added Successfully")
    }

    // history.push('/category')
  }
  function goback(e) {
    e.preventDefault()
    history.push('/SubCategoryList');
    window.location.reload()
  }
  return (
    <>


      <SideNavigation />

      <div className="main-panel">
        {/* <!-- Navbar --> */}
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
          <div class="container-fluid">
            <div class="navbar-wrapper">
              <h4 class="text-primary" style={{ fontSize: '15px' }}><b>Add Sub Category</b></h4>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">


              <ul class="navbar-nav">
                <li class="nav-item dropdown">
                  <a class="nav-link" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">notifications</i>
                    <span class="notification">{NotificationCount}</span>
                    <p class="d-lg-none d-md-block">
                      Some Actions
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    {Notification.map((item) =>
                      <>
                        <button class="dropdown-item">
                          <b> {item.Message}</b>
                        </button>
                      </>
                    )
                    }
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h4 className="mr-4 text-primary" style={{ fontSize: '15px' }}><i class="fa fa-sign-out"></i>{UserName}</h4>
                    <p class="d-lg-none d-md-block">
                      <Link onClick={logout} >Log out</Link>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">

                    {/* <div class="dropdown-divider"></div> */}
                    <Link class="dropdown-item" onClick={logout}>Log out</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* <!-- End Navbar --> */}
        <div className="content">
          <div className="container-fluid">
            <center> {(msg === null || msg === undefined) ?
              <div className="alert alert-danger mr-4" style={{ width: '48%' }}>All Fields Are Required</div> :
              (msg === "") ? ""
                : <div className="alert alert-success mr-4" style={{ width: '48%' }}>{msg}</div>
            } </center>

            <div className="row w-100 mt-5">
              <div className="col-md-3"></div>
              <div className="col-md-8">
                <div className="col-md-1"></div>
                <label className="" style={{ width: '50px', color: 'grey' }}>Name <span className="text-danger">*</span></label>
                <input type="text" onChange={(e) => setName(e.target.value)} style={{ width: '71%' }} className="form-control" required="required" />


                <label className="mt-5" style={{ width: '80px', color: 'grey' }}>Description<span className="text-danger">*</span></label>
                <input style={{ width: '71%' }} onChange={(e) => setDescription(e.target.value)} className="form-control" required="required" /><br />
                <div className="row">
                  {/* <div className="col-md-3"></div> */}
                  <div className="" style={{ width: '72%' }}>
                    <center>



                      <h6>Main Category<span className="text-danger ml-2">*</span></h6>
                      <MultiSelect

                        options={Category}
                        value={UserCategory}
                        onChange={setUserCategory}

                      />



                      {

                      }

                    </center>
                  </div>

                </div>
                <button type="submit" className="btn btn-danger float-left mt-4 ml-5" style={{ width: '140px', height: '40px' }} onClick={goback}>Go Back</button>
                <button type="submit" className="btn btn-primary float-left mt-4 ml-5" style={{ width: '170px' }} onClick={saveUser}>Add Sub Category</button>
              </div>
              <div className="col-md-1"></div>
            </div>

          </div>
        </div>
      </div>


    </>
  );

}
export default SubCategory