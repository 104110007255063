import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import UserSideNavigation from './UserSideNavigation';
import { apiUrl } from "../config/config";
import randomColor from "randomcolor";
function SubUserDashboard() {

  const [state, setState] = useState("");
  const history = useHistory();
  const [user, setUser] = useState([]);
  const [CategoryId, setCategoryId] = useState([]);
  const [cat, setCat] = useState([]);
  // const [item,setitem]=useState([]);
  var color = randomColor({
    count: 10,
    luminosity: 'dark',
    hue: 'purple,brown'
  });

  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('User_Info')
    setUser(JSON.parse(data));

  }, []);
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('categoryId')
    setCategoryId(JSON.parse(data));

  }, []);
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('SubCategory');
    setCat(JSON.parse(data));
  }, []);

  let UserId = user.Id;
  async function fetchList(itemId) {
    localStorage.setItem('subcategoryId', JSON.stringify(itemId));


    //   localStorage.setItem('categoryId',JSON.stringify(itemId));


    let subCategoryId = itemId;
    let data = { UserId, CategoryId, subCategoryId };
    let result = await fetch(apiUrl + "userdocument", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(data)

    });
    let dt = { UserId, CategoryId, subCategoryId };
    let res = await fetch(apiUrl + "insuserdocumentindividual", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(dt)

    });
    res = await res.json();
    localStorage.setItem('List', JSON.stringify(res));
    result = await result.json();
    if (result.AllowView) {
      localStorage.setItem('AllowViewList', JSON.stringify(result.AllowView));
      history.push('/FilesListView');
      //   window.location.reload(true);
    }
    else if (result.AllowEdit) {
      localStorage.setItem('AllowEditList', JSON.stringify(result.AllowEdit));
      history.push('/FileListEdit');
      //   window.location.reload(true);
    }
    else {
      localStorage.setItem('List', JSON.stringify(result.ownList));
      history.push('/FilesListing');
      //   window.location.reload(true);
    }



  }
  function logout() {
    localStorage.clear();
    history.push('/Login');
    window.location.reload(true);
  }
  function EditProfile() {
    history.push('/EditProfile');
    window.location.reload();
  }

  return (
    <>

      <UserSideNavigation />

      <div className="main-panel">
        {/* <!-- Navbar --> */}

        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
          <div class="container-fluid">
            <div class="navbar-wrapper">
              <h4 class="text-primary" style={{ fontSize: '15px' }}><b>Sub Dashboard</b></h4>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">


              <ul class="navbar-nav">

                <li class="nav-item dropdown">
                  <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h4 className="mr-4 text-primary" style={{ fontSize: '15px' }}>{user ? user.Name : ""}<i class="material-icons">download</i></h4>
                    <p class="d-lg-none d-md-block">
                      <Link onClick={logout} >Log out</Link>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                    <Link class="dropdown-item" onClick={EditProfile}>Edit Profile</Link>
                    <div class="dropdown-divider"></div>
                    <Link class="dropdown-item" onClick={logout}>Log out</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* <!-- End Navbar --> */}
        <div className="content">
          <div className="container-fluid">
            <center><div className="row my-5 pt-5">

              {cat.map((item) => {
                return (
                  <div
                    className="col-md-3 ml-5 pb-4 pt-4 my-3"
                    style={{ backgroundColor: color[item.Id] }}
                  >
                    <a style={{ cursor: 'pointer' }}
                      className="text-light"
                      onClick={() => fetchList(item.Id)}>
                      <h3 style={{ color: 'white' }}>

                        {item.SubCategoryName}
                      </h3>
                    </a>
                  </div>)
              })}


            </div>


            </center>
          </div>
        </div>
      </div>
    </>
  );

}
export default SubUserDashboard