import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import AllUsers from './AllUsers';
export default function SideNavigation() {
  let history = useHistory();
  function Dashboard() {
    history.push('/Dashboard')
    window.location.reload()
  }
  function AllUsers() {
    history.push('/AllUsers')
    window.location.reload()
  }
  function Category() {
    history.push('/Category')
    window.location.reload()
  }
  function Uploads() {
    history.push('/AssignUser')
    window.location.reload()
  }
  function logout() {
    localStorage.clear();
    history.push('/Login');
    window.location.reload(true);
  }
  function AddCategory() {
    history.push('/AddCategory')
    window.location.reload(true);
  }
  function SubCategory() {
    history.push('/SubCategory');
    window.location.reload(true);
  }
  function SubCategoryList() {
    history.push('/SubCategoryList');
    window.location.reload(true);
  }
  function LoggingList() {
    history.push('/Logging');
    window.location.reload(true);
  }
  return (

    <>

      <div class="sidebar" data-background-color="white" style={{ backgroundImage: 'url(../assets/img/sidebar-1.jpg)' }} data-image="../assets/img/sidebar-1.jpg">

        <div className="logo">

          <center><img src="./assets/img/logo.png" style={{ width: "200px", height: "160px" }} /></center>
        </div>
        <div class="sidebar-wrapper">
          <ul class="nav ml-3">
            <li class="nav-item active  ">

              <Link onClick={Dashboard} className="nav-link w-75 ml-1 ">
                <i class="material-icons" style={{ color: 'black' }}>dashboard</i>
                <p style={{ color: 'black', fontSize: '15px' }} className="">Dashboard</p>
              </Link>
            </li>
            <li class="nav-item ">

              <Link onClick={AllUsers} className="nav-link w-75 ml-1 ">
                <i class="material-icons" style={{ color: 'black' }}>person</i>
                <p style={{ color: 'black', fontSize: '15px' }} className="">All Users</p>

              </Link>

            </li>

            <li class="nav-item dropdown">
              <a className="nav-link w-75 ml-1" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <p style={{ color: 'black', fontSize: '15px' }} className="">Category
                  <i class="material-icons" style={{ color: 'black' }}>library_books</i></p>
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                <Link class="dropdown-item" onClick={AddCategory}>Add Category</Link>
                <Link class="dropdown-item" onClick={SubCategory}>Add SubCategory</Link>
                <Link class="dropdown-item" onClick={Category}>Category List</Link>
                <Link class="dropdown-item" onClick={SubCategoryList}>Sub Category List</Link>
              </div>
            </li>
            <li class="nav-item ">
              <Link onClick={Uploads} className="nav-link w-75 mt-3 ml-1">
                <i className="fa fa-upload" style={{ color: 'black' }}></i>
                <p style={{ color: 'black', fontSize: '15px' }} className="">Uploads</p>
              </Link>
            </li>
            <li class="nav-item ">
              <Link onClick={LoggingList} className="nav-link w-75 mt-3 ml-1">
                <i className="fa fa-history" style={{ color: 'black' }}></i>
                <p style={{ color: 'black', fontSize: '15px' }} className="">Logging</p>
              </Link>
            </li>
            <li class="nav-item ">
              <Link onClick={logout} className="nav-link w-75 mt-3 ml-1">
                <i className="fa fa-sign-out" style={{ color: 'black' }}></i>
                <p style={{ color: 'black', fontSize: '15px' }} className="">Log Out</p>
              </Link>
            </li>

          </ul>
        </div>
      </div>
    </>




  )
}