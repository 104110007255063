
import { render } from '@testing-library/react';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { apiUrl } from '../config/config';
export default function UserSideNavigation() {
  const [user, setUser] = useState([]);
  const [cat, setCat] = useState([]);
  const [fId, setFId] = useState();
  const history = useHistory();
  // const [UserId,setUserId]=useState("");
  // const [CategoryId,setCategoryId]=useState("");
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('User_Info')
    setUser(JSON.parse(data));

  }, []);
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('category');
    setCat(JSON.parse(data));
  }, []);

  let UserId = user.Id;
  async function fetchList(itemId) {
    localStorage.setItem('categoryId', JSON.stringify(itemId));
    let data = { UserId, itemId };
    let result = await fetch(apiUrl + "getsubcategory", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(data)

    });

    result = await result.json();
    localStorage.setItem('SubCategory', JSON.stringify(result));
    history.push('/SubUserDashboard');
    window.location.reload();
  }
  function UDashboard() {
    history.push('/UserDashboard')
    window.location.reload();
  }
  function logout() {
    localStorage.clear();
    history.push('/Login');
    window.location.reload(true);
  }
  function EditProfile() {
    history.push('/EditProfile');
    window.location.reload();
  }
  return (
    <>


      <div class="sidebar" data-background-color="white" data-image="../assets/img/sidebar-1.jpg">

        <div className="logo">

          <center><img src="./assets/img/logo.png" style={{ width: "200px", height: "160px" }} /></center>
        </div>
        <div class="sidebar-wrapper">
          <ul class="nav ml-3">
            <li class="nav-item active  ">

              <Link onClick={UDashboard} className="nav-link w-75 ml-1 ">
                <i class="material-icons" style={{ color: 'black' }}>home</i>
                <p style={{ color: 'black', fontSize: '15px' }} className="my-1"><b>Home</b></p>
              </Link>
            </li>
            <li class="nav-item dropdown">
              <a className="nav-link w-75 ml-1" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <p className="" style={{ color: 'black', fontSize: '15px' }}>
                  <i class="fa fa-sign-out" style={{ color: 'black' }}></i><b>Profile</b>

                </p>
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                <Link class="dropdown-item" onClick={EditProfile}>Edit Profile</Link>
                <Link class="dropdown-item" onClick={logout}>Log out</Link>

              </div>
            </li>
            <li className="nav-link text-align-left">

              {cat.map((item) => {

                return <>
                  <p style={{ fontSize: '15px' }}>
                    <i className="material-icons mt-3" style={{ color: 'black' }}>category</i>
                    <button
                      className="mt-3"
                      onClick={() => fetchList(item.Id)} style={{ color: 'black' }}>
                      {/* {item.Id}
                                <strong className="mr-3">)</strong> */}

                      {item.CategoryName}</button>
                  </p>
                  <br />

                </>

              })}
            </li>




          </ul>
        </div>
      </div>
    </>
  )
}
