import React, { Fragment, Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Link, useHistory, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Login from "./Components/Login";
import Test from "./Components/Test";
import Dashboard from "./Components/Dashboard";
import RegisterUser from "./Components/RegisterUser";
import Logging from "./Components/Logging";
import AllUsers from "./Components/AllUsers";
import Category from "./Components/Category";
import SubCategory from "./Components/SubCategory";
import SubCategoryList from "./Components/SubCategoryList";
import UpdateSubCategory from "./Components/UpdateSubCategory";
import AddCategory from "./Components/AddCategory";
import UpdateCategory from "./Components/UpdateCategory";
import Uploads from "./Components/Uploads";
import AssignUser from "./Components/AssignUser";
import AssignCategory from "./Components/AssignCategory";
import UpdateUser from "./Components/UpdateUser";
import SuperAdminLogin from "./Components/SuperAdminLogin";
import UserDashboard from "./UserComponents/UserDashboard";
import SubUserDashboard from "./UserComponents/SubUserDashboard";
import UploadDocument from "./UserComponents/UploadDocument";
import UpdateDocument from "./UserComponents/UpdateDocument";
import ViewOnly from "./UserComponents/ViewOnly";
import FileListEdit from "./UserComponents/FileListEdit";
import FilesListing from "./UserComponents/FilesListing";
import EditProfile from "./UserComponents/EditProfile";
import FilesListView from "./UserComponents/FilesListView";
import EditOnly from "./UserComponents/EditOnly";


function App() {

  return (
    <>

     
      <Route path="/" exact="/"><Login /></Route>
      <Route path="/Login"><Login /></Route>
      <Route path="/SuperAdminLogin"><SuperAdminLogin /></Route>

      <Route path="/Dashboard" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <Dashboard />
          )
      )}
      />
      <Route path="/Logging" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <Logging />
          )
      )}
      />

      <Route path="/UpdateSubCategory" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <UpdateSubCategory />
          )
      )}
      />

      <Route path="/SubCategory" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <SubCategory />
          )
      )}
      />

      <Route path="/SubCategoryList" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <SubCategoryList />
          )
      )}
      />

      <Route path="/RegisterUser" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <RegisterUser />
          )
      )}
      />
      <Route path="/AllUsers" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <AllUsers />
          )
      )}
      />
      <Route path="/Category" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <Category />
          )
      )}
      />
      <Route path="/AddCategory" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <AddCategory />
          )
      )}
      />
      <Route path="/UpdateCategory" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <UpdateCategory />
          )
      )}
      />

      <Route path="/Uploads" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <Uploads />
          )
      )}
      />
      <Route path="/AssignUser" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <AssignUser />
          )
      )}
      />

      <Route path="/UpdateUser" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <UpdateUser />
          )
      )}
      />
      <Route path="/AssignCategory" render={() => (
        localStorage.getItem('admin') === null
          || localStorage.getItem('admin') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <AssignCategory />
          )
      )}
      />



      <Route path="/UserDashboard" render={() => (
        localStorage.getItem('User_Info') === null
          || localStorage.getItem('User_Info') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <UserDashboard />
          )
      )}
      />

      <Route path="/SubUserDashboard" render={() => (
        localStorage.getItem('User_Info') === null
          || localStorage.getItem('User_Info') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <SubUserDashboard />
          )
      )}
      />

      <Route path="/UploadDocument" render={() => (
        localStorage.getItem('User_Info') === null
          || localStorage.getItem('User_Info') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <UploadDocument />
          )
      )}
      />
      <Route path="/EditProfile" render={() => (
        localStorage.getItem('User_Info') === null
          || localStorage.getItem('User_Info') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <EditProfile />
          )
      )}
      />
      <Route path="/FilesListing" render={() => (
        localStorage.getItem('User_Info') === null
          || localStorage.getItem('User_Info') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <FilesListing />
          )
      )}
      />


      <Route path="/UpdateDocument" render={() => (
        localStorage.getItem('User_Info') === null
          || localStorage.getItem('User_Info') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <UpdateDocument />
          )
      )}
      />

      <Route path="/ViewOnly" render={() => (
        localStorage.getItem('User_Info') === null
          || localStorage.getItem('User_Info') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <ViewOnly />
          )
      )}
      />
      <Route path="/FilesListView" render={() => (
        localStorage.getItem('User_Info') === null
          || localStorage.getItem('User_Info') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <FilesListView />
          )
      )}
      />

      <Route path="/EditOnly" render={() => (
        localStorage.getItem('User_Info') === null
          || localStorage.getItem('User_Info') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <EditOnly />
          )
      )}
      />

      <Route path="/FileListEdit" render={() => (
        localStorage.getItem('User_Info') === null
          || localStorage.getItem('User_Info') === undefined ?
          (
            <Redirect to="/Login" />
          )
          :
          (
            <FileListEdit />
          )
      )}
      />



    </>
  );

}

export default App
