import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios, { post } from 'axios';
import UserSideNavigation from './UserSideNavigation';
import { apiUrl, viewDocument, downloadDocument } from '../config/config';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function FilesListing(props) {
  const [Category, setCategory] = useState([]);
  const [file, setfile] = useState("");
  const [user, setUser] = useState([]);
  const [Id, setId] = useState([]);
  const [FName, setFName] = useState("");
  const [SubCategoryId, setSubCategoryId] = useState("")


  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('User_Info')
    setUser(JSON.parse(data));
  }, []);

  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('categoryId')
    setId(JSON.parse(data));
  }, []);
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('subcategoryId')
    setSubCategoryId(JSON.parse(data));

  }, []);
  let subcategoryId = SubCategoryId;
  let history = useHistory();
  let UserId = user.Id;
  let CgId = Id;

  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('FileName')
    setFName(JSON.parse(data));
  }, []);
  function download() {

    fetch(apiUrl + "downloaddocument/" + FName).then((data) => {
      data.json().then((resp) => {
        console.log("message", resp);

      })
    })

  }

  function uploadDocument() {
    history.push('/UploadDocument');
    window.location.reload();
  }
  function update(up) {
    localStorage.setItem('updateDocumentId', JSON.stringify(up))
    history.push('/UpdateDocument');
    window.location.reload();
  }
  function search(key) {

    fetch(apiUrl + "searchdocument/" + UserId + '/' + CgId + '/' + subcategoryId + '/' +
      key).then((data) => {
        data.json().then((resp) => {
          setCategory(resp);
        })
      })



  }

  function refresh() {
    fetch(apiUrl + "userdocumentindividual/" + UserId + '/' + CgId + '/' + subcategoryId).then((result) => {
      result.json().then((resp) => {
        setCategory(resp);
        localStorage.setItem('List', JSON.stringify(resp))
        console.log("result", resp);
      })
    })
  }
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('List')
    setCategory(JSON.parse(data));
    // window.location.reload()
  }, []);
  function logout() {
    localStorage.clear();
    history.push('/Login');
    window.location.reload(true);
  }
  function EditProfile() {
    history.push('/EditProfile');
  }

  return (
    <>


      <UserSideNavigation />
      <div className="main-panel">
        {/* Nav */}
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
          <div class="container-fluid">
            <div class="navbar-wrapper">
              <h4 class=" text-primary" style={{ fontSize: '15px' }}><b>Files Listing</b></h4>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">


              <ul class="navbar-nav">

                <li class="nav-item dropdown">
                  <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h4 className="mr-4 text-primary" style={{ fontSize: '15px' }}>{user ? user.Name : ""}<i class="material-icons">download</i></h4>
                    <p class="d-lg-none d-md-block">
                      <Link onClick={logout} >Log out</Link>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                    <Link class="dropdown-item" onClick={EditProfile}>Edit Profile</Link>
                    <div class="dropdown-divider"></div>
                    <Link class="dropdown-item" onClick={logout}>Log out</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* <!-- End Navbar --> */}

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                {/* <center> */}

                {/* <Loader
          type="Oval"
          color="blue"
          height={40}
          width={40}
          timeout={3000} //3 secs
        /></center> */}
                <div className="row w-100 mt-3">
                  <div className="col-md-6">
                    <button
                      className="btn btn-primary"
                      onClick={uploadDocument}>
                      Upload Document
                    </button>
                  </div>

                  <div className="col-md-5 float-right">
                    <label className="" style={{ width: '100px', color: 'grey' }}>Search</label>
                    <input type="text"
                      onChange={(e) => search(e.target.value)}
                      style={{ width: '100%' }}
                      className="form-control float-right" />

                  </div>



                  <div className="col-md-12 mt-5">

                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr>
                            <button className=" w-100 " style={{ color: 'blue' }} onClick={refresh}>

                              Refresh <i className="fa fa-refresh"></i>
                            </button>
                          </tr><br />

                          <tr className="text-primary">
                            <th>Id</th>
                            <th>Date</th>
                            <th>Name</th>
                            <th>View</th>
                            <th>Download</th>
                            <th>Update</th>
                          </tr>

                          {

                            Category.map((item, index) =>
                              <>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.Date}</td>
                                  <td>{item.Name}</td>


                                  <td>
                                    <button className='btn btn-info'
                                      onClick={() => window.open(viewDocument + item.FileName)}>
                                      View
                                    </button>
                                  </td>
                                  <td>

                                    <button className='btn btn-success' onClick={() => window.open(downloadDocument + item.FileName)
                                    }>Download

                                    </button>
                                  </td>
                                  <td>

                                    <button className='btn btn-primary' onClick={() => update(item.FileName)}>Update

                                    </button>
                                  </td>
                                </tr>


                              </>
                            )
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>




    </>
  );

}
export default FilesListing