import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios, { post } from 'axios';
import SideNavigation from './SideNavigation';
import { apiUrl, viewDocument, downloadDocument } from '../config/config';
function AssignUser() {
  const [Category, setCategory] = useState([]);
  const [user, setUser] = useState([]);

  const UserName = "Hi Admin";
  const [NotificationCount, setNotificationCount] = useState("");
  const [Notification, setNotification] = useState([]);
  useEffect(() => {
    fetch(apiUrl + "countnotification").then((result) => {
      result.json().then((resp) => {
        console.log('notify', resp)
        setNotificationCount(resp);
      })
    })
  }, [])
  useEffect(() => {
    fetch(apiUrl + "notification").then((result) => {
      result.json().then((resp) => {
        console.log('notify', resp)
        setNotification(resp);
      })
    })
  }, [])
  useEffect(() => {
    fetch(apiUrl + "assignuser").then((result) => {
      result.json().then((resp) => {
        setCategory(resp);
      })
    })
  }, [])



  let history = useHistory();
  function search(key) {
    if (key) {
      fetch(apiUrl + "searchassignuser/" + key).then((data) => {
        data.json().then((resp) => {
          setCategory(resp);
        })
      })
    }
    else {
      return "";
    }


  }
  function logout() {
    localStorage.clear();
    history.push('/Login');
    window.location.reload(true);
  }

  function view(itemId) {
    localStorage.setItem('viewUserId', JSON.stringify(itemId));
    history.push('/AssignCategory')
    window.location.reload(true);
  }
  return (
    <>

      <SideNavigation />

      <div className="main-panel">
        {/* Nav */}
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
          <div class="container-fluid">
            <div class="navbar-wrapper">
              <h4 class="text-primary" style={{ fontSize: '15px' }}><b>Assign User</b></h4>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">


              <ul class="navbar-nav">
                <li class="nav-item dropdown">
                  <a class="nav-link" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">notifications</i>
                    <span class="notification">{NotificationCount}</span>
                    <p class="d-lg-none d-md-block">
                      Some Actions
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    {Notification.map((item) =>
                      <>
                        <button class="dropdown-item">
                          <b> {item.Message}</b>
                        </button>
                      </>
                    )
                    }
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h4 className="mr-4 text-primary" style={{ fontSize: '15px' }}><i class="fa fa-sign-out"></i>Hi Admin</h4>
                    <p class="d-lg-none d-md-block">
                      <Link onClick={logout} >Log out</Link>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">

                    {/* <div class="dropdown-divider"></div> */}
                    <Link class="dropdown-item" onClick={logout}>Log out</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* <!-- End Navbar --> */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">

                <div className="row w-100 mt-3">
                  <div className="col-md-6">

                  </div>

                  <div className="col-md-5 float-right">
                    <label className="" style={{ width: '120px', color: 'grey' }}>Search By Name</label>
                    <input type="text"
                      onChange={(e) => search(e.target.value)}
                      style={{ width: '72%' }}
                      className="form-control 
                                    "/>

                  </div>



                  <div className="col-md-12 mt-5">

                    <div className="table-responsive">
                      <table className="table">
                        <tbody>

                          <tr className="text-primary">
                            <th>Id</th>
                            <th>Person</th>
                            <th>Email</th>

                            <th>View</th>

                          </tr>

                          {

                            Category.map((item, index) =>
                              <>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.Name}</td>
                                  <td>{item.Email}</td>


                                  <td>
                                    <button className='btn btn-info'
                                      onClick={() => view(item.Id)}>
                                      View
                                    </button>
                                  </td>




                                </tr>


                              </>
                            )
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );

}
export default AssignUser