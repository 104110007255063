import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import SideNavigation from './SideNavigation';
import { apiUrl } from '../config/config';

export default function Dashboard() {

  const [CategoryCount, setCategoryCount] = useState("");
  const [UserCount, setUserCount] = useState("");
  const [NotificationCount, setNotificationCount] = useState("");
  const [Notification, setNotification] = useState([]);
  const history = useHistory();
  const [user, setUser] = useState([]);
  let admin = "Hi Admin"
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('admin')
    setUser(JSON.parse(data));

  }, []);
  // let UserName=user.Name;
  function logout() {
    localStorage.clear();
    history.push('/Login');
    window.location.reload(true);
  }
  function EditProfile() {
    history.push('/EditProfile');
  }
  useEffect(() => {
    fetch(apiUrl + "countcategory").then((result) => {
      result.json().then((resp) => {
        setCategoryCount(resp);
      })
    })
  }, [])
  useEffect(() => {
    fetch(apiUrl + "countalluser").then((result) => {
      result.json().then((resp) => {
        setUserCount(resp);
      })
    })
  }, [])
  useEffect(() => {
    fetch(apiUrl + "countnotification").then((result) => {
      result.json().then((resp) => {
        console.log('notify', resp)
        setNotificationCount(resp);
      })
    })
  }, [])
  useEffect(() => {
    fetch(apiUrl + "notification").then((result) => {
      result.json().then((resp) => {
        console.log('notify', resp)
        setNotification(resp);
      })
    })
  }, [])
  const getLocal = (() => {
    let login = localStorage.getItem('admin');
    if (login) {
      return JSON.parse(localStorage.getItem('admin'));
    }
    else {
      return [];
    }

  });
  function cateD() {
    history.push('/Category')
    window.location.reload()
  }
  function userD() {
    history.push('/AllUsers')
    window.location.reload()
  }


  return (
    <>
      {/* <div> */}
      <SideNavigation />
      <div class="main-panel">
        {/* <!-- Navbar --> */}
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
          <div class="container-fluid">
            <div class="navbar-wrapper">
              <p class=" text-primary" style={{ fontSize: '15px' }}><b>Dashboard</b></p>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">


              <ul class="navbar-nav">
                <li class="nav-item dropdown">
                  <a class="nav-link" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">notifications</i>
                    <span class="notification">{NotificationCount}</span>
                    <p class="d-lg-none d-md-block">
                      Some Actions
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    {Notification.map((item) =>
                      <>
                        <button class="dropdown-item">
                          <b> {item.Message}</b>
                        </button>
                      </>
                    )
                    }
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h5 className="mr-4 text-primary" style={{ fontSize: '15px' }}><i class="fa fa-sign-out"></i>{admin}</h5>
                    <p class="d-lg-none d-md-block">
                      <Link onClick={logout} >Log out</Link>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">

                    {/* <div class="dropdown-divider"></div> */}
                    <Link class="dropdown-item" onClick={logout}>Log out</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* <!-- End Navbar --> */}


        {/*Main Body Starts  */}

        <div className="content my-5">
          <div className="container-fluid my-5">
            <div className="row w-100 my-5 pt-5">

              <div class="col-lg-2 col-md-6 col-sm-6"></div>
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card card-stats" style={{ color: 'purple' }}>
                  <div class="card-header card-header-warning card-header-icon" >
                    <div class="card-icon">
                      <i class="material-icons">category</i>

                    </div>

                  </div>
                  <div class="card-footer">
                    <div class="stats ml-2">
                      <center>
                        <Link onClick={cateD}>
                          <h3 style={{ color: 'purple', textDecoration: 'underline' }} className="pt-2">Category</h3>
                        </Link><br />
                        <h4 style={{ color: 'purple' }}>Total: {CategoryCount}</h4></center>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-1 col-md-6 col-sm-6"></div>

              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card card-stats" style={{ color: 'purple' }}>
                  <div class="card-header card-header-warning card-header-icon" >
                    <div class="card-icon">
                      <i class="material-icons">person</i>

                    </div>

                  </div>
                  <div class="card-footer">
                    <div class="stats ml-2">
                      <center>
                        <Link onClick={userD}>
                          <h3 style={{ color: 'purple', textDecoration: 'underline' }} className="pt-2">User</h3>
                        </Link><br />
                        <h4 style={{ color: 'purple' }}>Total: {UserCount}</h4></center>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* </div> */}



    </>
  );
}