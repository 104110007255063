import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios, { post } from 'axios';
import { apiUrl } from '../config/config';
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import UserSideNavigation from './UserSideNavigation';
function UploadDocument() {
  const [Name, setName] = useState("");
  const [Description, setDescription] = useState("");
  const [FData, setFData] = useState("");
  const history = useHistory();
  const [Cat, setCat] = useState([])
  const [Imagedata, setImagedata] = useState("");
  const [progress, setProgress] = useState()

  const handleChange = file => {
    setImagedata(file[0]);

  }
  const [user, setUser] = useState([]);
  const [CategoryId, setCategoryId] = useState("")
  const [SubCategoryId, setSubCategoryId] = useState("")
  let view = localStorage.getItem('AllowViewList');
  let edit = localStorage.getItem('AllowEditList');
  // let view=localStorage.getItem('AllowViewList');


  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('User_Info')
    setUser(JSON.parse(data));

  }, []);
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('categoryId')
    setCategoryId(JSON.parse(data));

  }, []);
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('subcategoryId')
    setSubCategoryId(JSON.parse(data));

  }, []);
  // useEffect(()=> {
  //   //logic for getting a local storage value
  //   const data = localStorage.getItem('category');
  //   setCat(JSON.parse(data));
  // },[]);
  let User = user.Id;
  let categoryId = CategoryId;
  let subcategoryId = SubCategoryId;

  function logout() {
    localStorage.clear();
    history.push('/Login');
    window.location.reload(true);
  }
  function EditProfile() {
    history.push('/EditProfile');
  }
  function submitData() {
    // e.preventDefault();
    if (Name === "" || Imagedata === "" || Description === "" || Name === undefined || Imagedata === undefined ||
      Name === null || Imagedata === null) {
      const fData = new FormData();
      fData.append('Image', Imagedata);
      axios.post(apiUrl + 'adduserdocument/'
        + "" + '/' + "" + '/' + User + '/' + CategoryId,
        fData)
      localStorage.removeItem('document');
      console.log("clear", Imagedata)
    }
    else {
      if (Imagedata) {
        const fData = new FormData();
        fData.append('Image', Imagedata);
        axios.post(apiUrl + 'adduserdocument/'
          + Name + '/' + Description + '/' + User + '/' + CategoryId + '/' + subcategoryId,
          fData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: data => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total))
          },
        })
        localStorage.setItem('document', JSON.stringify(Name));
        console.log("ok", Imagedata)
      }
      else {
        return "";
      }
    }
    const data = localStorage.getItem('document')
    setFData(data);

  }

  function goback(e) {
    e.preventDefault()
    if (view) {
      history.push('/FilesListView');
      window.location.reload()
    }
    else if (edit) {
      history.push('/FileListEdit');
      window.location.reload()
    }
    else {
      history.push('/FilesListing');
      window.location.reload()
    }

  }
  return (
    <>

      <UserSideNavigation />

      <div className="main-panel">
        {/* <!-- Navbar --> */}
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
          <div class="container-fluid">
            <div class="navbar-wrapper">
              <h4 class="text-primary" style={{ fontSize: '15px' }}><b>Upload Document</b></h4>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">


              <ul class="navbar-nav">

                <li class="nav-item dropdown">
                  <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h4 className="mr-4 text-primary" style={{ fontSize: '15px' }}>{user ? user.Name : ""}<i class="material-icons">download</i></h4>
                    <p class="d-lg-none d-md-block">
                      <Link onClick={logout} >Log out</Link>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                    <Link class="dropdown-item" onClick={EditProfile}>Edit Profile</Link>
                    <div class="dropdown-divider"></div>
                    <Link class="dropdown-item" onClick={logout}>Log out</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* <!-- End Navbar --> */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">

                <div className="row w-100 mt-3">

                  <div className="col-md-3"></div>
                  <div className="col-md-8">
                    {FData ? <div className="alert alert-success pt-3" style={{ width: '72%' }}><h5 className="pl-4">Document Uploading </h5></div>
                      : FData === null ? <div className="alert alert-danger pt-3" style={{ width: '72%' }}><h5 className="pl-4">All Fields Are Required</h5></div> : ""}
                    {progress && <ProgressBar now={progress} label={`${progress}%`} style={{ width: '72%', height: '40px' }} />}
                    <label className="" style={{ width: '100px', color: 'grey' }}>Name<span className="text-danger ml-2">*</span></label>
                    <input type="text" name="name" id="name" onChange={(e) => setName(e.target.value)} style={{ width: '72%' }} className="form-control" required="required" />


                    <label className="mt-4" style={{ width: '100px', color: 'grey' }}>Description<span className="text-danger ml-2">*</span></label>
                    <input style={{ width: '72%' }} name="description" id="description" onChange={(e) => setDescription(e.target.value)} className="form-control" />


                    <label className="mt-4" style={{ width: '100px', color: 'grey' }}>Upload File<span className="text-danger ml-2">*</span></label>
                    <input type="file" name="image" id="image" onChange={(e) => handleChange(e.target.files)} style={{ width: '72%' }} className="form-control" required="required" /><br />



                    <button type="submit" onClick={goback} className="btn btn-danger float-left mt-4 ml-5" style={{ width: '140px', height: '40px' }}>Go Back</button>

                    <button type="submit" onClick={submitData} className="btn btn-primary ml-5 float-left mt-4" style={{ width: '180px', height: '40px' }}>Upload Document</button>

                  </div>
                  <div className="col-md-1"></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>




    </>
  );

}
export default UploadDocument