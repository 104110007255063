import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import UserSideNavigation from './UserSideNavigation';
import { apiUrl } from '../config/config';
function EditProfile() {
  const [Name, setName] = useState("");
  const [Contact, setContact] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Designation, setDesignation] = useState("");
  const history = useHistory();
  const [msg, setMSG] = useState("");
  const [user, setUser] = useState([]);
  const [Names, setNames] = useState("");
  let Id = user.Id;


  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('User_Info')
    setUser(JSON.parse(data));

  }, []);
  function logout() {
    localStorage.clear();
    history.push('/Login');
    window.location.reload(true);
  }
  function EditProfile() {
    history.push('/EditProfile');
  }
  useEffect(() => {
    //logic for getting a local storage value
    const data = localStorage.getItem('User_Info')
    setUser(JSON.parse(data));
  }, []);

  function saveUser() {
    //
    let data = { Id, Name, Contact, Email, Password, Designation };


    fetch(apiUrl + "editprofile", {
      method: 'Post',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(data)
    }).then((result) => {
      console.warn("result", result);

    })
    if (Name === "" || Contact === "" || Email === "" || Password === "" || Designation === "") {
      setMSG(null)
    }
    else {
      setMSG("Profile Edited Successfully")
    }

  }
  function goback(e) {
    e.preventDefault()
    history.push('/UserDashboard');
    window.location.reload()
  }
  return (
    <>

      <UserSideNavigation />

      <div className="main-panel " >
        {/* <!-- Navbar --> */}
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
          <div class="container-fluid">
            <div class="navbar-wrapper">
              <h4 class="text-primary" style={{ fontSize: '15px' }}><b>Edit Profile</b></h4>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">


              <ul class="navbar-nav">

                <li class="nav-item dropdown">
                  <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h4 className="mr-4 text-primary" style={{ fontSize: '15px' }}>{user ? user.Name : ""}<i class="material-icons">download</i></h4>
                    <p class="d-lg-none d-md-block">
                      <Link onClick={logout} >Log out</Link>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                    <Link class="dropdown-item" onClick={EditProfile}>Edit Profile</Link>
                    <div class="dropdown-divider"></div>
                    <Link class="dropdown-item" onClick={logout}>Log out</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* <!-- End Navbar --> */}
        <div className="content">
          <div className="container-fluid">
            <center> {(msg === null || msg === undefined) ?
              <div className="alert alert-danger mr-4" style={{ width: '48%' }}>All Fields Are Required</div> :
              (msg === "") ? ""
                : <div className="alert alert-success mr-4" style={{ width: '48%' }}>{msg}</div>
            } </center>
            <div className="row">
              <div className="col-md-12">

                <div className="row">

                  <div className="col-md-3"></div>
                  <div className="col-md-6 ">
                    <div className=" mt-3">
                      <label className="pl-3" style={{ width: '20px', color: 'grey' }}>Name<span className="ml-2" style={{ color: 'red' }}>*</span></label>
                      <input type="text" onChange={(e) => setName(e.target.value)} className="pl-3 ml-3 form-control w-75" required="required" />
                    </div>
                    <div className="mt-4">
                      <label className="pl-3" style={{ width: '20px', color: 'grey' }}>Contact<span className="ml-2" style={{ color: 'red' }}>*</span></label>
                      <input type="text" onChange={(e) => setContact(e.target.value)} className="w-75 pl-3 ml-3 form-control" required="required" />
                    </div>
                    <div className="mt-4">
                      <label className="pl-3" style={{ width: '20px', color: 'grey' }}>Email<span className="ml-2" style={{ color: 'red' }}>*</span></label>
                      <input type="email" onChange={(e) => setEmail(e.target.value)} className="w-75 pl-3 ml-3 form-control" required="required" />
                    </div>
                    <div className="mt-4">
                      <label className="pl-3" style={{ width: '20px', color: 'grey' }}>Password<span className="ml-2" style={{ color: 'red' }}>*</span></label>
                      <input type="password" onChange={(e) => setPassword(e.target.value)} className="w-75 pl-3 ml-3 form-control" required="required" />
                    </div>
                    <div className="mt-4">
                      <label className="pl-3" style={{ width: '20px', color: 'grey' }}>Designation<span className="ml-2" style={{ color: 'red' }}>*</span></label>
                      <input type="text" onChange={(e) => setDesignation(e.target.value)} className="w-75 pl-3 ml-3 form-control" required="required" />
                    </div>
                    <div>
                      <button type="submit" onClick={goback}
                        className="btn btn-danger
            float-left mt-4 ml-4"
                        style={{ width: '140px', height: '40px' }}>

                        Go Back
                      </button>

                      <button type="submit"
                        onClick={saveUser}
                        className="btn btn-primary float-left ml-3 mt-4"
                        style={{ width: '140px' }}>
                        Edit Profile
                      </button>

                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );

}
export default EditProfile